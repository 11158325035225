window.addEventListener('trix-initialize', (event) => {
  // const galleryButtonHTML: String = `
  //   <button type="button" class="trix-button fs-6 py-1 fw-normal" data-trix-attribute="gallery"
  //     onclick="
  //       const trix = document.querySelector('trix-editor');
  //       trix.editor.insertString('[Gallery]');
  //       trix.editor.insertLineBreak();
  //     ">
  //     <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#666666"><path d="M0 0h24v24H0z" fill="none"/><path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/></svg>
  //     ギャラリー追加
  //   </button>
  //   `;

  const tableButtonHTML: String = `
    <button type="button" class="trix-button fs-6 py-1 fw-normal js-custom-table-button" data-trix-attribute="table"
      onclick="
        const trix = document.querySelector('trix-editor');
        trix.editor.insertString('[Table]');
        trix.editor.insertLineBreak();
        trix.editor.insertString('タイトル | テキスト');
        trix.editor.insertLineBreak();
        trix.editor.insertString('タイトル | テキスト');
        trix.editor.insertLineBreak();
        trix.editor.insertString('[/Table]');
        trix.editor.insertLineBreak();
      ">
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#666666"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 3v18h18V3H3zm8 16H5v-6h6v6zm0-8H5V5h6v6zm8 8h-6v-6h6v6zm0-8h-6V5h6v6z"/></svg>
      テーブル追加
    </button>
    `;
  const buttonRow: String = `
    <div class="trix-button-row js-button-row mb-2">
      <div class="trix-button-group js-button-group"></div>
    </div>
  `;

  event.target.toolbarElement
    .querySelector<HTMLDivElement>('.trix-button-row')
    .insertAdjacentHTML('afterend', buttonRow);
  // event.target.toolbarElement.querySelector<HTMLDivElement>(".js-button-group").insertAdjacentHTML("beforeend", galleryButtonHTML);
  event.target.toolbarElement
    .querySelector<HTMLDivElement>('.js-button-group')
    .insertAdjacentHTML('beforeend', tableButtonHTML);

  if (event.target.dataset.isInline) {
    event.target.toolbarElement.querySelector<HTMLSpanElement>(
      '.trix-button-group--block-tools'
    ).style.display = 'none';

    event.target.toolbarElement.querySelector<HTMLButtonElement>(
      '.js-custom-table-button'
    ).style.display = 'none';

    event.target.toolbarElement.querySelector<HTMLSpanElement>(
      '.trix-button-group--file-tools'
    ).style.display = 'none';
  }
});
